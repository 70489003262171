import { Box, Button, Typography, TextField, Grid } from '@partner-tech/pax-ui-kit';
import { tokenStorage } from 'core/LegacySharedComponents/authorization';
import React, { useState, useEffect, useCallback } from 'react';
import { MarketsCzechRepublicIllustration } from '@partner-tech/pax-illustrations/zalando-partner';
import PrivacyPolicy from 'components/PrivacyPolicyModal/PrivacyPolicy';
import PropTypes from 'prop-types';

const sucessMsg = {
  id: 'user_update_success',
  headline: 'Your profile has been updated',
  message:
    "Thanks for providing us with your data. You're now all set to start exploring the Retail Center!",
  autoHideDuration: null,
  status: 'success'
};

export const UsersNameForm = props => {
  const [visible, setVisible] = useState(true);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const originalOverflow = document.body.style.overflow;
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = originalOverflow;
    };
  }, []);

  const { setNewUserNameAdded } = props;

  const changeFirstName = e => {
    setFirstName(e.target.value);
  };
  const changeLastName = e => {
    setLastName(e.target.value);
  };

  const displayNotification = () => {
    window.RetailPortal.snackbar.show(sucessMsg);
  };

  const showPrivacyPolicy = () => {
    setIsModalOpen(true);
  };

  const handleCancel = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const updateUsersName = async () => {
    const token = tokenStorage.getToken();
    const url = `${AUTH_SERVICE_URL}/api/supplier-user-details`;
    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-type': 'application/json'
      },
      method: 'PUT',
      body: JSON.stringify({ first_name: firstName.trim(), last_name: lastName.trim() })
    };

    try {
      const response = await fetch(url, options);

      if (response?.status === 204) {
        displayNotification();
        setNewUserNameAdded(true);
      }
      setVisible(false);
      document.body.style.overflow = 'none';
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('error in "/api/supplier-user-details" api ', err);
      setVisible(false);
      document.body.style.overflow = 'none';
    }
  };

  const disabledUpdate = !firstName.trim() || !lastName.trim();

  useEffect(() => {}, [isModalOpen, handleCancel]);

  return (
    visible && (
      <Box
        data-testid="usersname-form"
        sx={{
          backgroundColor: 'semantic.background.default',
          padding: '110px 20px',
          width: '100%',
          zIndex: '5',
          display: 'flex',
          justifyContent: 'center',
          position: 'absolute',
          height: '100vh',
          top: '0',
          left: '0',
          bottom: '0'
        }}
      >
        <Grid container>
          <Grid container flexDirection="column" rowGap={3}>
            <Typography variant="headline L" color="semantic.text.primary" mb={3}>
              Hi, welcome on the Retail Center!
            </Typography>
            <Typography variant="body M" color="semantic.text.body" mb={4}>
              We need some information to let you access the Retail Center.
            </Typography>
            <TextField
              fullWidth
              label="First name"
              data-testid="fname"
              value={firstName}
              onChange={changeFirstName}
            />
            <TextField
              fullWidth
              label="Last name"
              data-testid="lname"
              value={lastName}
              onChange={changeLastName}
            />
            <Typography variant="body M" color="semantic.text.body" mt={4}>
              Your data will remain protected, in accordance with our{' '}
              <strong style={{ cursor: 'pointer' }} onClick={showPrivacyPolicy} role="presentation">
                <u>privacy notice.</u>{' '}
              </strong>
            </Typography>
            <Grid>
              <Button
                data-testid="usersname-form-close-btn"
                disabled={disabledUpdate}
                onClick={updateUsersName}
                style={{ marginTop: '24px' }}
              >
                Update profile
              </Button>
            </Grid>
          </Grid>
          <MarketsCzechRepublicIllustration
            style={{ width: '270px', height: '290px', marginTop: '-29px' }}
          />
          <PrivacyPolicy isModalOpen={isModalOpen} handleCancel={handleCancel} />
        </Grid>
      </Box>
    )
  );
};

UsersNameForm.propTypes = {
  setNewUserNameAdded: PropTypes.func.isRequired
};
