import React from 'react';
import { Snackbar, Link } from '@partner-tech/pax-ui-kit';
import PropTypes from 'prop-types';
import { useStyles } from './SnackbarList.styles';

const horizontalPositions = ['left', 'right', 'center'];
const verticalPositions = ['top', 'bottom'];

const SnackbarList = ({ list, removeMessage }) => {
  const { classes } = useStyles();

  const handleClose = (id, callback) => (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    if (callback && typeof callback === 'function') {
      callback();
    }

    removeMessage(id);
  };

  const renderSnackbar = item => {
    const {
      id,
      headline,
      message,
      anchorOrigin,
      autoHideDuration,
      status,
      onClose,
      cta,
      simple,
      ...props
    } = item;
    const handleCloseItem = handleClose(id, onClose);

    return (
      <Snackbar
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props} // Pass all other props to Snackbar
        key={id}
        anchorOrigin={anchorOrigin}
        classes={{
          root: classes.snackBar
        }}
        open
        autoHideDuration={autoHideDuration}
        onClose={handleCloseItem}
        headline={headline}
        message={message}
        closeIcon={simple ? null : undefined}
        ContentProps={{
          className: simple ? classes.smallSnackBarContent : ''
        }}
        type={status}
        cta={
          !simple &&
          item.cta &&
          item.cta.label &&
          item.cta.onClick && (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <Link variant="label L" onClick={item.cta.onClick} darkMode>
              {item.cta.label}
            </Link>
          )
        }
      />
    );
  };

  const getFilteredItems = (xPos, yPos) =>
    list.filter(
      item => item.anchorOrigin.horizontal === xPos && item.anchorOrigin.vertical === yPos
    );

  return (
    <>
      {horizontalPositions.map(xPos => (
        <div key={xPos}>
          {verticalPositions.map(yPos => {
            const filteredItems = getFilteredItems(xPos, yPos);
            if (!filteredItems.length) {
              return null;
            }

            return (
              <div
                className={`${classes.snackBarList} ${classes[`${xPos}SnackBarList`]} ${
                  classes[`${yPos}SnackBarList`]
                }`}
                key={`${xPos}-${yPos}`}
                data-testid={`${xPos}-${yPos}-snackbar-list`}
              >
                {filteredItems.map(renderSnackbar)}
              </div>
            );
          })}
        </div>
      ))}
    </>
  );
};

// Add prop validation for 'list'
SnackbarList.propTypes = {
  list: PropTypes.array.isRequired,
  removeMessage: PropTypes.func.isRequired
};

export default SnackbarList;
